import React, { useEffect, useState, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import { ShowActiveTab } from '../../../../Context'
import organisms from '../../../../components/organisms'
import molecules from '../../../../components/molecules'
import service from '../../../../api/services'
import { Default_Page_Limit } from '../../../../constants/defaultValues'
import { handleFileDownload } from '../../../../constants/utils'
import constant from '../../../../constants/constant'
import { useToasts } from 'react-toast-notifications'
const { PendingReceipt } = organisms
const { TopBar } = molecules
const Index = () => {
  const { addToast } = useToasts();
  const [receiptData, setReceiptData] = useState<any>()
  const [initialLoading, setInitialLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState(Default_Page_Limit);
  const { setActiveTab }: any = useContext(ShowActiveTab)
  const getFilterList = async () => {
    try {
      const response = await service.PendingOrder.list({ search: search, page: currentPage, limit: limit, status: constant?.ORDER_STATUS?.PENDING })

      if (response.status === 200) {
        setReceiptData(response.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handlePrintSharePdf = async (id: string, share?: boolean) => {
    try {
      let response;
      if (share) {
        response = await service.SalesCart.sharePdf(id, { share });
        addToast(response?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      } else {
        response = await service.SalesCart.generatePdf(id);
        if (response.status === 200) {
          handleFileDownload(response?.data?.data?.path)
        }
      }

    } catch (error: any) {
      console.log('error', error)
      addToast(error?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }
  const handleOnHoldOrder = async (id: string) => {
    try {
      let response;
      response = await service.PendingOrder.onHold(id);
      getFilterList()
      addToast(response?.data?.message, {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });

    } catch (error: any) {
      console.log('error', error)
      addToast(error?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }
  const handleReadyOrder = async (id: string) => {
    try {
      let response;
      response = await service.PendingOrder.ready(id);
      getFilterList()
      addToast(response?.data?.message, {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });

    } catch (error: any) {
      console.log('error', error)
      addToast(error?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }

  useEffect(() => {
    getFilterList()
  }, [search, currentPage, limit])

  useEffect(() => {
    setActiveTab("pending")
    setCurrentPage(1)
    if (currentPage == 1 && initialLoading) {
      getFilterList()
      setInitialLoading(true)
    }
  }, [limit])
  return (
    <Wrapper>
      <div className='row align-items-center'>
        <TopBar heading='Pending Order' />
      </div>
      <div className='mt-3'>
        <PendingReceipt
          receiptData={receiptData}
          setSearch={setSearch}
          limit={limit}
          currentPage={currentPage}
          setLimitPerPage={setLimit}
          handlePrintSharePdf={(id: string, share?: boolean) => handlePrintSharePdf(id, share)}
          setCurrentPage={(page: number) => setCurrentPage(page)}
          handleOnHoldOrder={(id: string) => handleOnHoldOrder(id)}
          handleReadyOrder={(id: string) => handleReadyOrder(id)}
        />
      </div>
    </Wrapper>
  )
}

export default Index