import React from "react";
import atoms from "../../../atoms";
import Images from '../../../../assets/Images'
import constant from "../../../../constants/constant";
import { findConstantLabel, numberWithCommas } from "../../../../constants/utils";

const { Button, CustomImages, Image } = atoms
const { PlaceholderImg } = Images

const Index = ({ isViewData, handleSalesReturnButton, setShowEmptyCard }: any) => {

    const showEmptyCardCondition = (status: number) => {
        if (status === constant?.Sale_Return_Item_Condition) {
            setShowEmptyCard(false)
        } else {
            setShowEmptyCard(true)

        }
    }
    return (
        <>
            {(isViewData?.status !== constant?.Sale_Return_Item_Condition) ? <div className='checkout-card mt-3'>
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <div className='d-flex mb-3 flex-wrap gap-3 align-items-center'>
                        <div className='d-flex flex-wrap align-items-center gap-3'>
                            <h2 className='mb-0 fs-14 fw-600'>Invoice ID :-</h2>
                            <div className='id-input-design d-flex justify-content-between align-items-center gap-2'>
                                <h2 className='mb-0 fs-12 fw-400'>{isViewData?.order?.invoice_number}</h2>
                                <img src={'Copy'} alt="" className='img-fluid' />
                            </div>
                        </div>

                        {isViewData?.product?.product_id_display && <div className='d-flex flex-wrap align-items-center gap-3'>
                            <h2 className='mb-0 fs-14 fw-600'>Product ID :-</h2>
                            <div className='id-input-design d-flex justify-content-between align-items-center gap-2'>
                                <h2 className='mb-0 fs-12 fw-400'>{isViewData?.product?.product_id_display}</h2>
                                <img src={'Copy'} alt="" className='img-fluid' />
                            </div>
                        </div>}
                    </div>

                    {<div className="d-none d-md-flex mt-md-0 mb-3">
                        <Button
                            className='px-3 mt-md-0'
                            isDisabled={isViewData?.sales_return === "EXPIRED"}
                            onClick={() => handleSalesReturnButton(isViewData?.product?.product_id_display, isViewData?.id)}>
                            Sales Return
                        </Button>
                    </div>}

                </div>

                <div className="row ">
                    <div className="col-12 col-md-3">
                        <CustomImages src={isViewData?.product?.images[0]} />
                    </div>

                    <div className="col-12 col-md-9 mt-4">
                        {isViewData?.product ?
                            <div className='d-flex flex-wrap justify-content-between align-items-end '>
                                <div>
                                    <h2 className='mb-0 fs-16 fw-600 dark-gray'>{isViewData?.product?.brand?.brand_name}</h2>
                                    {isViewData?.product?.is_contact_lens && <div className="d-flex gap-3 align-items-center my-2 ">
                                        <h2 className='mb-0 fs-14 fw-400 dark-gray'>Color: <span className='mb-0 fs-14 fw-600'>{ isViewData?.product?.color?.color_name}</span></h2>
                                        <h2 className='mb-0 fs-14 fw-400 dark-gray'>Pack Quantity: <span className='mb-0 fs-14 fw-600'> {isViewData?.product?.pack_of_quantity}</span></h2>
                                        <h2 className='mb-0 fs-14 fw-400 dark-gray'>Pack of: <span className='mb-0 fs-14 fw-600'>{isViewData?.product?.period_use}</span></h2>
                                    </div>}
                                    {!isViewData?.product?.is_contact_lens && <div className="d-flex gap-3 align-items-center my-2 ">
                                        <h2 className='mb-0 fs-14 fw-400 dark-gray'>Model Number: <span className='mb-0 fs-14 fw-600'>{isViewData ? isViewData?.product?.modal_number : "-"}</span></h2>
                                        <h2 className='mb-0 fs-14 fw-400 dark-gray'>Warranty: <span className='mb-0 fs-14 fw-600'> {findConstantLabel(constant?.PRODUCT_WARRANTY, isViewData?.product?.warranty)}</span></h2>
                                        <h2 className='mb-0 fs-14 fw-400 dark-gray'>Polarized: <span className='mb-0 fs-14 fw-600'>{findConstantLabel(constant?.POLARIZED, isViewData?.product?.isPolarised)}</span></h2>
                                    </div>}

                                    {!isViewData?.product?.is_contact_lens && <div className="d-flex gap-3 align-items-center mb-2">
                                        <h2 className='mb-0 fs-14 fw-400 dark-gray'>Color Code: <span className='mb-0 fs-14 fw-600'>{isViewData ? isViewData?.product?.brand_color_code : "-"}   </span></h2>
                                        <h2 className='mb-0 fs-14 fw-400 dark-gray'>With Case: <span className='mb-0 fs-14 fw-600'>{isViewData?.product?.product_with_case ? "Yes" : "No"} </span></h2>
                                        <h2 className='mb-0 fs-14 fw-400 dark-gray'>Size/Power: <span className='mb-0 fs-14 fw-600'>{isViewData ? isViewData?.product?.glass_size : "-"} </span></h2>
                                    </div>}
                                    <p className='mb-0 fs-14 fw-500 dark-gray mt-3'>{isViewData?.product?.description}</p>
                                </div>
                                <div className='text-sm-start text-md-start text-lg-end mt-3 mt-md-0'>
                                    <p className='mb-0 fs-16 fw-600 dark-gray'>Paid :<span className='mb-0 ps-2 fs-16 fw-600 dark-gray'>₹{numberWithCommas(isViewData?.product?.product_srp - isViewData?.discount)}</span></p>
                                </div>
                                <Button
                                    className='d-md-none px-3 mt-md-0 w-100 mt-3'
                                    isDisabled={isViewData?.sales_return === "EXPIRED"}
                                    onClick={() => handleSalesReturnButton(isViewData?.product?.product_id_display, isViewData?.id)}>
                                    Sales Return
                                </Button>
                            </div>
                            :
                            <div className='d-flex flex-wrap justify-content-between align-items-end'>
                                {/* for additional product */}
                                <div>
                                    <h2 className='mb-0 fs-16 fw-600 dark-gray'>{isViewData?.other_product_data?.product_type_name}</h2>
                                    {isViewData?.other_product_data?.product_type_name === "Lens" && <>
                                        <div className='mt-2'>
                                            <h3 className='fs-14 fw-500 dark-gray'>Name : {isViewData?.other_product_data?.lens_details?.name}</h3>
                                            <h3 className='fs-14 fw-500 dark-gray'>Phone : {isViewData?.other_product_data?.lens_details?.phone} </h3>
                                            <div className="card p-3 mb-2">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h3 className='text-center fs-16 fw-600 dark-gray'>{`Right Eye (OD)`}</h3>
                                                        <div className="row">
                                                            <div className="col fs-12 fw-500 dark-gray"></div>
                                                            <div className="col fs-12 fw-500 dark-gray">SPH</div>
                                                            <div className="col fs-12 fw-500 dark-gray">CYL</div>
                                                            <div className="col fs-12 fw-500 dark-gray">AXIS</div>
                                                            <div className="col fs-12 fw-500 dark-gray">PD</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col fs-12 fw-500 dark-gray">DV:</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.r_dv_sph}</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.r_dv_cyl}</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.r_dv_axis}</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.r_dv_pd}</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col fs-12 fw-500 dark-gray">NV:</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.r_nv_sph}</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.r_nv_cyl}</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.r_nv_axis}</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.r_nv_pd}</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col fs-12 fw-500 dark-gray">ADD:</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.r_add}</div>
                                                            <div className="col fs-12 fw-500 dark-gray"></div>
                                                            <div className="col fs-12 fw-500 dark-gray"></div>
                                                            <div className="col fs-12 fw-500 dark-gray"></div>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-6" style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.175)' }}>
                                                        <h3 className='text-center fs-16 fw-600 dark-gray'>{`Left Eye (OS)`}</h3>
                                                        <div className="row">
                                                            <div className="col fs-12 fw-500 dark-gray"></div>
                                                            <div className="col fs-12 fw-500 dark-gray">SPH</div>
                                                            <div className="col fs-12 fw-500 dark-gray">CYL</div>
                                                            <div className="col fs-12 fw-500 dark-gray">AXIS</div>
                                                            <div className="col fs-12 fw-500 dark-gray">PD</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col fs-12 fw-500 dark-gray">DV:</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.l_dv_sph}</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.l_dv_cyl}</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.l_dv_axis}</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.l_dv_pd}</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col fs-12 fw-500 dark-gray">NV:</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.l_nv_sph}</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.l_nv_cyl}</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.l_nv_axis}</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.l_nv_pd}</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col fs-12 fw-500 dark-gray">ADD:</div>
                                                            <div className="col fs-12 fw-500 dark-gray">{isViewData?.other_product_data?.lens_details?.l_add}</div>
                                                            <div className="col fs-12 fw-500 dark-gray"></div>
                                                            <div className="col fs-12 fw-500 dark-gray"></div>
                                                            <div className="col fs-12 fw-500 dark-gray"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                    <p className='mb-0 fs-14 fw-500 dark-gray mt-3'>{isViewData?.other_product_data?.description}</p>
                                </div>
                                <div className='text-sm-start text-md-start text-lg-end mt-3 mt-md-0'>
                                    <p className='mb-0 fs-16 fw-600 dark-gray'>Paid :<span className='mb-0 ps-2 fs-16 fw-600 dark-gray'>₹{isViewData?.other_product_data?.price - isViewData?.discount}</span></p>
                                </div>
                            </div>
                        }

                    </div>
                </div>

            </div> : ""
            }
            <>{showEmptyCardCondition(isViewData?.status)}</>
        </>
    )
}

export default Index