
export const PlaceHolderImage = 'https://images.pexels.com/photos/1054777/pexels-photo-1054777.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
export const LoginImage = 'https://assets.myntassets.com/dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/16905012/2023/7/26/1f947a56-e8f6-4893-9848-d79b196823b31690350140172-Voyage-Unisex-Black-Lens--Silver-Toned-Round-Sunglasses-with-1.jpg'

export const BASE_URL = process.env.REACT_APP_API_URL
// export const BASE_URL = 'http://192.168.1.184:6001/api/'
export const ADMIN_PORTAL_URL = process.env.REACT_APP_ADMIN_URL

export const Default_Page_Limit = 20

export const Store_Legal_Status = [
    { value: 1, label: 'Propritorship' },
    { value: 2, label: 'Partnership' },
    { value: 3, label: 'Company' },
]
export const limitOptions = [20, 50, 100, 200];

export const Pt_Lense = ['Lens', 'lens', 'Contact Lens', 'contact lens'];

export const Cart_discard_timer = process.env.REACT_APP_CART_TIMER
export const Cart_popup_timer = process.env.REACT_APP_CART_POPUP_TIMER
export const Contact_lens = 'contact lens'
