import React from 'react'
import atoms from '../../atoms'
import { useNavigate } from "react-router-dom";
import constant from '../../../constants/constant';
import { findConstantLabel, numberWithCommas } from '../../../constants/utils';
import CustomImages from '../../atoms/CustomImages';
import { warrantyProps, warrantyProduct } from './interface'
const { Button, Input } = atoms

const Index: React.FC<warrantyProps> = ({ warrantyProductData, warrantyProductList, handleChange,searchDetail }) => {
    // const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate()

    return (
        <>
            <div className="row">
                <div className='col-4 pe-0'>
                    <Input
                        type={'text'}
                        className="input-height input-container-height"
                        value={searchDetail?.invoice_number}
                        placeholder={window?.innerWidth<768 ? "Invoice No." : "Enter Invoice Number"}
                        onChange={(e: any) => {
                            handleChange("invoice_number", e.target.value.replace(/[^0-9\_-]/g, ""))
                        }} />
                </div>
                <div className='col-4 pe-0'>
                    <Input
                        type='text'
                        className="input-height input-container-height"
                        placeholder={window?.innerWidth<768 ? "Modal No." : "Enter Modal Number"}
                        onChange={(e: any) => {
                            handleChange("modal_number", e.target.value.replace(/\s/g, ''))
                        }} />
                </div>
                <div className='col-4'>
                    <Button onClick={()=>warrantyProductList()}>Search</Button>
                </div>
            </div>

            <div className="row">
                <div className="col mt-3">
                    {
                        warrantyProductData?.length > 0 ? (
                            warrantyProductData.map((product: warrantyProduct, index: number) => (
                                (
                                    <div className="row">
                                        <div className="col mt-4">
                                            <div className='checkout-card'>
                                                <div className="d-flex flex-wrap justify-content-between">
                                                    <div className='d-flex flex-wrap gap-3 align-items-center mb-md-0 mb-3'>
                                                        <div className='d-flex flex-wrap align-items-center gap-3'>
                                                            <h2 className='mb-0 fs-14 fw-600 warranty-invoice-width'>Invoice ID :-</h2>
                                                            <div className='id-input-design d-flex justify-content-between align-items-center gap-2'>
                                                                <h2 className='mb-0 fs-12 fw-500'>{product?.order?.invoice_number}</h2>
                                                                <img src={'Copy'} alt="" className='img-fluid' />
                                                            </div>
                                                        </div>

                                                        <div className='d-flex flex-wrap align-items-center gap-3'>
                                                            <h2 className='mb-0 fs-14 fw-600'>Product ID :-</h2>
                                                            <div className='id-input-design d-flex justify-content-between align-items-center gap-2'>
                                                                <h2 className='mb-0 fs-12 fw-500'>{product?.product?.product_id_display}</h2>
                                                                <img src={"Copy"} alt="" className='img-fluid' />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='d-none d-md-flex mb-md-0 mb-3 justify-content-end'>
                                                        <Button className='px-5' onClick={() => navigate(`/warranty-detail/${product?.id}`)}>Claim</Button>
                                                    </div>

                                                </div>

                                                <div className="row ">
                                                    <div className="col-12 col-md-3 mt-4">
                                                        <CustomImages src={product?.product?.images?.length > 0 && product?.product?.images[0]} imageHeight='130px' />
                                                    </div>

                                                    <div className="col-12 col-md-9 mt-4">
                                                        <div className='d-flex flex-wrap justify-content-between align-items-end '>
                                                            <div>
                                                                <h2 className='mb-0 fs-16 fw-600 dark-gray'>{product?.product?.brand?.brand_name}</h2>
                                                                <div className="d-flex gap-3 align-items-center my-2 ">
                                                                    <h2 className='mb-0 fs-md-14 fs-12 fw-400 dark-gray'>Model {window.innerWidth < 768 ? 'No' : 'Number'} : <span className='mb-0 fs-md-14 fs-12 fw-600'>{product?.product?.modal_number}</span></h2>
                                                                    <h2 className='mb-0 fs-md-14 fs-12 fw-400 dark-gray'>Warranty: <span className='mb-0 fs-md-14 fs-12 fw-600'> {findConstantLabel(constant?.PRODUCT_WARRANTY, product?.product?.warranty)} </span></h2>
                                                                    <h2 className='mb-0 fs-md-14 fs-12 fw-400 dark-gray'>Polarized: <span className='mb-0 fs-md-14 fs-12 fw-600'>{findConstantLabel(constant?.POLARIZED, product?.product?.isPolarised)}</span></h2>
                                                                </div>

                                                                <div className="d-flex gap-3 align-items-center mb-2">
                                                                    <h2 className='mb-0 fs-md-14 fs-12 fw-400 dark-gray'>Color Code: <span className='mb-0 fs-md-14 fs-12 fw-600'>{product?.product?.brand_color_code}</span></h2>
                                                                    <h2 className='mb-0 fs-md-14 fs-12 fw-400 dark-gray'>With Case: <span className='mb-0 fs-md-14 fs-12 fw-600'>{product?.product?.product_with_case ? "Yes" : "No"} </span></h2>
                                                                    <h2 className='mb-0 fs-md-14 fs-12 fw-400 dark-gray'>Size/Power: <span className='mb-0 fs-md-14 fs-12 fw-600'>{product?.product?.glass_size}MM</span></h2>
                                                                </div>
                                                                <p className='mb-0 fs-14 fw-500 dark-gray mt-3'>{product?.product?.description}</p>
                                                            </div>
                                                            <div className='text-sm-start text-md-start text-lg-end mt-3 mt-md-0'>
                                                                {/* <p className='mb-0 fs-16 fw-500 dark-gray text-decoration-line-through'>₹245,78</p> */}
                                                                <p className='mb-0 fs-16 fw-600 dark-gray'>Paid :<span className='mb-0 ps-2 fs-18 fw-600 dark-gray'>₹{numberWithCommas(product?.product?.product_srp - product?.discount)}</span></p>
                                                            </div>

                                                        </div>
                                                        <Button className='d-md-none w-100 mt-3' onClick={() => navigate(`/warranty-detail/${product?.id}`)}>Claim</Button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )
                            ))
                        ) : (
                            <div className='justify-content-center d-flex secondary-color'>
                                There are no records to display
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default Index