const salesCart = {
  addToCart: () => 'store/cart/add',
  addLensDetail: (lensId:string) => `store/cart/lens-details`,
  addPendingLensDetail: (lensId:string) => `store/order/lens-details`,
  search: () => 'store/customer/search',
  viewCart: () => 'store/cart/view',
  removeItem: (cartItemId: string) => `store/cart/remove-item/${cartItemId}`,
  repeatItem: (cartItemId: string) => `store/cart/repeat-item/${cartItemId}`,
  checkout: () => `store/cart/checkout`,
  advanceReceipt: () => `store/cart/advance-receipt`,
  createInvoice: () => `store/cart/invoice`,
  addAdditionalProducts: () => `store/cart/add/other-product`,
  EditItem: (cartItemId:string) => `store/cart/item/${cartItemId}/other-product`,
  generatePdf: (orderId:string) => `store/order/${orderId}/download-receipt`,
  cancelCart: () => `store/cart/cancel`,
  resumeCart: () => `store/cart/resume`,

}
export default salesCart
