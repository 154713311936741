import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { salesCart } = Dictionary

const SalesCart = {
  addToCart: async (data: {}) => {
    return axios.post(BASE_URL + salesCart.addToCart(), data)
  },
  addLensDetail: async (lensId: string, data: {}) => {
    return axios.post(BASE_URL + salesCart.addLensDetail(lensId), data)
  },
  updateLensDetail: async (lensId: string, data: {}) => {
    return axios.put(BASE_URL + salesCart.addLensDetail(lensId), data)
  },
  updatePendingLensDetail: async (lensId: string, data: {}) => {
    return axios.put(BASE_URL + salesCart.addPendingLensDetail(lensId), data)
  },
  search: async (paramsObj: {}) => {
    return axios.get(BASE_URL + salesCart.search(), { params: paramsObj })
  },
  viewCart: async () => {
    return axios.get(BASE_URL + salesCart.viewCart())
  },
  removeItem: async (cartItemId: string) => {
    return axios.put(BASE_URL + salesCart.removeItem(cartItemId))
  },
  repeatItem: async (cartItemId: string) => {
    return axios.post(BASE_URL + salesCart.repeatItem(cartItemId))
  },
  EditItem: async (cartItemId: string, payload: {}) => {
    return axios.put(BASE_URL + salesCart.EditItem(cartItemId), payload)
  },
  checkout: async (payload: {}) => {
    return axios.post(BASE_URL + salesCart.checkout(), payload)
  },
  advanceReceipt: async (payload: {}) => {
    return axios.post(BASE_URL + salesCart.advanceReceipt(), payload)
  },
  createInvoice: async (payload: {}) => {
    return axios.post(BASE_URL + salesCart.createInvoice(), payload)
  },
  addAdditionalProducts: async (payload: {}) => {
    return axios.post(BASE_URL + salesCart.addAdditionalProducts(), payload)
  },
  generatePdf: async (orderId: string) => {
    return axios.get(BASE_URL + salesCart.generatePdf(orderId))
  },
  sharePdf: async (orderId: string, paramsObj: {}) => {
    return axios.get(BASE_URL + salesCart.generatePdf(orderId), {
      params: paramsObj,
    })
  },
  cancelCart: async () => {
    return axios.delete(BASE_URL + salesCart.cancelCart())
  },
  resumeCart: async () => {
    return axios.get(BASE_URL + salesCart.resumeCart())
  },
}
export default SalesCart
