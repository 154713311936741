const pandingOrder = {
  viewOrderDetails: (id: string) => `store/order/${id}`,
  salesOrderList: () => 'store/orders',
  filter: () => 'inventory/filters',
  receiptById: (id: string) => `store/order/${id}`,
  removeItem: (id: string) => `store/order/remove-item/${id}`,
  repeatItem: (id: string) => `store/order/repeat-item/${id}`,
  addItem: (id: string) => `store/order/${id}/add-product`,
  checkout: (id: string) => `store/order/${id}/checkout`,
  advanceReceipt: (id: string) => `store/order/${id}/advance-receipt`,
  createInvoice: (id: string) => `store/order/${id}/invoice-receipt`,
  onHold: (id: string) => `store/order/${id}/hold`,
  ready: (id: string) => `store/order/${id}/ready`,
  addAdditionalProducts: (orderId: string) => `store/order/${orderId}/other-product`,
  EditItem: (cartItemId: string) => `store/order/${cartItemId}/other-product`,
  cancelOrder: (orderID: string) => `store/order/${orderID}/cancel`,
}
export default pandingOrder
