export default {
  LIMIT: 10,

  PRODUCT_lIST_PATH: '/product/list',

  SCOPE: {
    ADMIN: '1',
    MANAGER: '2',
    STAFF: '3',
  },
  VERIFICATION_STATUS: {
    PENDING: 1,
    VERIFIED: 2,
    REWORK: 3,
  },
  BARCODE_STATUS: {
    PENDING: 1,
    APPROVED: 2,
  },
  STORE_FURNITURE_TYPE: [
    { label: 'Luxury AC Showroom', value: 1 },
    { label: 'Premium AC Showroom', value: 2 },
    { label: 'Non AC Good Furnised Store', value: 3 },
    { label: 'Basic Furnised Store', value: 4 },
    { label: 'Low Furnised Store', value: 5 },
  ],
  STATUS: {
    ACTIVE: 'Active',
    DEACTIVE: 'Deactive',
  },
  GENDER: [
    { label: 'Male', value: 1 },
    { label: 'Female', value: 2 },
    { label: 'Kids', value: 3 },
  ],
  PRODUCT_WEIGHT: [
    { label: 'LIGHT', value: 1 },
    { label: 'AVERAGE', value: 2 },
    { label: 'ABOVE AVERAGE', value: 3 },
  ],
  // PRODUCT_AGE_GROUP: [
  //   { label: 'KIDS', value: 1 },
  //   { label: 'TEENAGER', value: 2 },
  //   { label: 'ADULT', value: 3 },
  //   { label: 'OLD', value: 4 },
  // ],
  PRODUCT_FEATURES: [
    { label: 'Matt', value: 1 },
    { label: 'Gradient', value: 2 },
    { label: 'Transparent', value: 3 },
    { label: 'Blue Cut', value: 4 },
    { label: 'Mirror', value: 5 },
    { label: 'Photocromatic', value: 6 },
    { label: 'Demi Havana', value: 7 },
    { label: 'Fis Nosepad', value: 8 },
  ],

  PRODUCT_RIM_TYPE: [
    { label: 'FULL RIM', value: 1 },
    { label: 'HALF RIM', value: 2 },
    { label: 'RIM LESS', value: 3 },
  ],
  PRODUCT_WARRANTY: [
    { label: '6 Months', value: 1 },
    { label: '12 Months', value: 2 },
    { label: '24 Months', value: 3 },
  ],

  POLARIZED: [
    { label: 'YES', value: 1 },
    { label: 'NO', value: 2 },
    { label: 'NA', value: 3 },
  ],
  GST_DETAILS: {
    REGULAR: 1,
    COMPOSITION: 2,
    NA: 3,
  },
  DOC_TYPE: {
    gst_number: 'GST',
    gst_document: 'GST',
    pan_number: 'PAN',
    pan_document: 'PAN',
    gumasta_number: 'GUMASTA',
    gumasta_document: 'GUMASTA',
    nagar_nigam_number: 'NAGAR_NIGAM',
    nagar_nigam_document: 'NAGAR_NIGAM',
    incorporation_number: 'INCORPORATION',
    incorporation_document: 'INCORPORATION',
    agency_number: 'AGENCY',
    agency_document: 'AGENCY',
  },
  INVOICE_CATEGORY: [
    { label: 'INVOICE', value: 1 },
    { label: 'WARRANTY', value: 2 },
    { label: 'FREE GOODS', value: 3 },
    { label: 'SAMPLE', value: 4 },
  ],
  ORDER_STATUS: {
    PENDING: 1,
    DELIVERED: 2,
    CANCELED: 3,
    READY: 4,
    ON_HOLD: 5,
  },

  //   ORDER_STATUS: {
  //     PENDING: 1,
  //     COMPLETED: 2,
  //     CANCEL: 3,
  //     READY: 4,
  //     ON_HOLD: 5
  // },

  ORDER_ITEM_STATUS: {
    PENDING: 1,
    DELIVERED: 2,
    READY_TO_DELIVERED: 3,
    SALES_RETURN: 4,
  },
  ORDER_ITEM_SALES_HISTORY: 2,

  CART_ITEM_STATUS: {
    PENDING: 1,
    DELIVERED: 2,
  },
  PROCUREMENT_ITEM_STATUS: {
    PENDING: 1,
    TO_BE_DELIVERED: 2,
    DELIVERED: 3,
    CANCEL: 4,
  },

  INVENTORY_ITEM_CONDITION: [
    { label: 'GOOD SALEABLE', value: 1 },
    { label: 'MINOR DEFECT', value: 2 },
    { label: 'MAJOR DEFECT', value: 3 },
    { label: 'NOT SALEABLE', value: 4 },
  ],

  SALES_RETURN_ISSUE_TYPE: {
    PAY_CASH: 1,
    ISSUE_CREDIT_NOTE: 2,
  },

  WARRANTY_CLAIM_APPROVAL_STATUS: [
    { label: 'PENDING', value: 1 },
    { label: 'APPROVED', value: 2 },
    { label: 'REJECTED', value: 3 },
  ],

  WARRANTY_CLAIM_RESOLUTION_STATUS: [
    { label: 'PENDING', value: 1 },
    { label: 'REPLACED', value: 2 },
    { label: 'CREDIT_NOTE', value: 3 },
    { label: 'REQUESTED', value: 4 },
    { label: 'REJECTED', value: 5 },
  ],

  warrantee_claim_for: [
    { label: 'Frame Color Fade', value: 1 },
    { label: 'Lens Color Fade', value: 2 },
    { label: 'Break from joints', value: 3 },
    { label: 'Manufacturing Fault', value: 4 },
  ],

  Sale_Return_Item_Condition: 4,

  mode_of_payment: [
    { value: 1, label: 'Cash' },
    { value: 2, label: 'Bank/UPI' },
  ],

  Account_Detail_Constant: {
    SALES_CART: 1,
    PENDING_ORDER: 2,
  },

  Product_List_Price_Sort: {
    High_To_Low: 1,
    Low_To_High: 2,
  },
  EYE_TYPE_CONTACT_LENS: {
    RIGHT_EYE: 1,
    LEFT_EYE: 2,
    BOTH_EYE: 3,
  },

  Product_List_Search_Option: [
    { label: 'Model No.', value: '1' },
    { label: 'Barcode', value: '2' },
  ],

  Product_Transit_Days_Undefined: 'undefined - undefined Days',
}
