import React, {useState} from 'react';
import './interface'
import BrandPlaceHolder from '../../../assets/Images/placeholderimg.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ImagePopup from '../ImagePopup';
import atoms from '../../atoms';
import './Carousel.scss'
import constant from '../../../constants/constant';
const { AvailableBadge,UnavailableBadge,PendingBadge } = atoms
const CarouselComp = ({ productDetails }: any): any => {
    const [showImageLarge, setShowImageLarge] = useState(false)
    const [imageIndex, setImageIndex] = useState<any>()
    const [imageUrl, setImageUrl] = useState<any[]>([])

    const carouselImages = productDetails?.images
    const isAvailable = productDetails?.available_at_store
    const isOutOfStock = productDetails?.out_of_stock

    const closeImage = () => {
        setShowImageLarge((prevState) => !prevState)
        setImageIndex(0)
    }

    const showImage = (imageUrlData: any, index: any) => {
        setShowImageLarge((prevState) => !prevState)
        setImageIndex(index)
        setImageUrl(imageUrlData)
    }
    return (

        <div className='position-relative' style={{ border: '1px solid #C2D1D9', borderRadius: '10px', }} >
            <Carousel className=''
            // swipeable={true} emulateTouch
            >
                {carouselImages && carouselImages.map((src: string, idx: number) =>
                    <div key={idx} style={{ backgroundImage: `url(${src || BrandPlaceHolder})` }} className='product-carousal-img'
                    onClick={()=>showImage(imageUrl, idx)}>
                    </div>
                )}
            </Carousel>
            {isAvailable && <div className="product-detail-badge">
                <AvailableBadge />
            </div>}
            {(!isAvailable && productDetails?.transit_days && productDetails?.transit_days !== constant.Product_Transit_Days_Undefined) && <div className="product-detail-badge">
                <PendingBadge title={`Arriving In: ${productDetails?.transit_days}`}/>
            </div>}
            {isOutOfStock && <div className=" product-detail-badge not-available-in-market">
                <UnavailableBadge title='NOT AVAILABLE IN MARKET'/>
            </div>}

            {showImageLarge &&
                <>
                    <ImagePopup imageUrlData={carouselImages} handleClose={closeImage} Index={imageIndex} />
                </>
            }
        </div>
    );
}

export default CarouselComp
