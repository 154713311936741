import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { pendingOrder } = Dictionary

const PendingOrder = {
  viewOrderDetails: async (id: string) => {
    return axios.get(BASE_URL + pendingOrder.viewOrderDetails(id))
  },
  list: async (paramsObj: {}) => {
    return axios.get(BASE_URL + pendingOrder.salesOrderList(), { params: paramsObj })
  },
  delivered: async (paramsObj: {}) => {
    return axios.get(BASE_URL + pendingOrder.salesOrderList(), { params: paramsObj })
  },
  cancelledOrder: async (paramsObj: {}) => {
    return axios.get(BASE_URL + pendingOrder.salesOrderList(), { params: paramsObj })
  },
  filter: async () => {
    return axios.get(BASE_URL + pendingOrder.filter())
  },
  receiptByID: async (id: string) => {
    return axios.get(BASE_URL + pendingOrder.receiptById(id))
  },
  removeItem: async (cartItemId: string) => {
    return axios.delete(BASE_URL + pendingOrder.removeItem(cartItemId))
  },
  addItem: async (id: string, data: {}) => {
    return axios.post(BASE_URL + pendingOrder.addItem(id), data,)
  },
  EditItem: async (cartItemId: string, payload: {}) => {
    return axios.put(BASE_URL + pendingOrder.EditItem(cartItemId), payload)
  },
  addAdditionalProducts: async (orderId: string, payload: {}) => {
    return axios.post(BASE_URL + pendingOrder.addAdditionalProducts(orderId), payload)
  },
  checkout: async (id: string, payload: {}) => {
    return axios.post(BASE_URL + pendingOrder.checkout(id), payload)
  },
  advanceReceipt: async (id: string, payload: {}) => {
    return axios.put(BASE_URL + pendingOrder.advanceReceipt(id), payload)
  },
  createInvoice: async (id: string, payload: {}) => {
    return axios.put(BASE_URL + pendingOrder.createInvoice(id), payload)
  },
  repeatItem: async (id: string) => {
    return axios.post(BASE_URL + pendingOrder.repeatItem(id))
  },
  cancelOrder: async (orderID: string) => {
    return axios.delete(BASE_URL + pendingOrder.cancelOrder(orderID))
  },
  onHold: async (id: string) => {
    return axios.put(BASE_URL + pendingOrder.onHold(id))
  },
  ready: async (id: string) => {
    return axios.put(BASE_URL + pendingOrder.ready(id))
  },
}
export default PendingOrder
